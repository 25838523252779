import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import * as FullStory from '@fullstory/browser';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

FullStory.init({
  orgId: 'o-1JX4F5-na1',
  devMode: process.env.NODE_ENV === 'development',
});

// import { H } from 'highlight.run';
// import { ErrorBoundary } from '@highlight-run/react';

// H.init('ng2pzye1', {
//   tracingOrigins: true,
//   networkRecording: {
//     enabled: true,
//     recordHeadersAndBody: true,
//     urlBlocklist: [
//       // insert urls you don't want to record here
//     ],
//   },
// });

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />} />
      </Routes>
      {/* <ErrorBoundary> */}
      {/* </ErrorBoundary> */}
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
