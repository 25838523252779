import { useEffect, useState } from 'react';
import axios from 'axios';
import PromptInput from '../PromptInput/PromptInput';
import './App.css';
import {
  ResponseInterface,
  CharacterType,
} from '../PromptResponseList/response-interface';
import PromptResponseList from '../PromptResponseList/PromptResponseList';

import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import ReactMarkdown from 'react-markdown';
import { Buffer } from 'buffer';
import toast, { Toaster } from 'react-hot-toast';
import * as FullStory from '@fullstory/browser';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const README = `**GroupChatGPT** evolves [ChatGPT](https://openai.com/blog/chatgpt) from an awkward 1:1 conversation into a familiar group chat with your favorite characters.

Sometimes we learn more by listening than by talking...

This group chat is a lot of fun to play with but there are some surprisingly interesting ideas hidden underneath.

It combines two compelling prompting patterns that hackers have been exploring:

1. Using the [System Message](https://platform.openai.com/docs/guides/chat/introduction) to guide ChatGPT to assume different worldviews (role playing)
2. Using self-ask loops to help the model reason about things (see ReAct, MRKL, etc)

The end-results are fascinating and engaging conversations that draw you in with new ideas that you may not have thought of before.

The approach of alternating between multiple GPT worldviews/personas in loops may prove especially powerful in creative, educational, and research-based applications.

Ask the group a question and see for yourself!

This implementation of GroupChatGPT is _extremely_ basic... lots of room to build on and improve.

[rfreling.dev](https://rfreling.dev)


`;

const getMessages = (_responseList: any) => {
  return _responseList
    .filter((item: any) => {
      return item.response;
    })
    .map((item: any) => {
      if (item.character === 'self') {
        return {
          role: 'user',
          content: item.response,
        };
      } else {
        return {
          role: 'user',
          content: `${item.character}: ${item.response}`,
        };
      }
    });
};

const RadioButton = ({ name, id, value, onChange, checked, text }: any) => {
  return (
    <label htmlFor={id} className='radio-label'>
      <input
        className='radio-input'
        type='radio'
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className='custom-radio' />
      {text}
    </label>
  );
};

const App = (props: any) => {
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const to64 = (responseList: any) => {
    return Buffer.from(JSON.stringify(responseList)).toString('base64');
  };

  const from64 = (encoded: string) => {
    return JSON.parse(Buffer.from(encoded, 'base64').toString());
  };

  const defaultResponseList = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const conversation = urlParams.get('conversation');

    if (conversation) {
      toast.success('Loaded conversation!');
      return from64(conversation);
    } else {
      return [];
    }
  };

  const copyConversationLink = () => {
    const conversation = to64(responseList);
    const shareable = `${window.location.origin}?conversation=${conversation}`;
    navigator.clipboard.writeText(shareable);
    toast.success('Copied to clipboard!');
  };

  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const copyConversation = () => {
    let conversation = '';
    responseList.forEach((item: any) => {
      if (item.character === 'self') {
        conversation += 'Me: ' + item.response + '\n\n';
      } else {
        conversation +=
          capitalize(item.character) + ': ' + item.response + '\n\n';
      }
    });
    navigator.clipboard.writeText(conversation);
    toast.success('Copied to clipboard!');
  };

  const [responseList, setResponseList] =
    useState<ResponseInterface[]>(defaultResponseList);
  const [prompt, setPrompt] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [maxResponseCount, setMaxResponseCount] = useState(3);
  const [model, setModel] = useState('gpt-3.5-turbo');

  const generateUniqueId = () => {
    const timestamp = Date.now();
    const randomNumber = Math.random();
    const hexadecimalString = randomNumber.toString(16);

    return `id-${timestamp}-${hexadecimalString}`;
  };

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const loop = async () => {
    const mostRecentResponse = responseList[responseList.length - 1];

    let largestIndexOfLastUserQuestion = 0;
    responseList.forEach((item, index) => {
      if (item.character === 'self') {
        largestIndexOfLastUserQuestion = index;
      }
    });
    const messagesSinceLastUserQuestion =
      responseList.length - 1 - largestIndexOfLastUserQuestion;

    try {
      if (responseList.length === 0) {
        return;
      } else if (mostRecentResponse.response === undefined) {
        if (messagesSinceLastUserQuestion % 2 === 0) {
          const response = await axios.post('get-prompt-result', {
            messages: getMessages(responseList),
            character: mostRecentResponse.character,
            retort: true,
            model,
          });

          updateResponse(mostRecentResponse.id, {
            response: response.data.trim(),
          });
        } else {
          const response = await axios.post('get-prompt-result', {
            messages: getMessages(responseList),
            character: mostRecentResponse.character,
            retort: false,
            model,
          });

          updateResponse(mostRecentResponse.id, {
            response: response.data.trim(),
          });
        }
        return;
      } else if (messagesSinceLastUserQuestion >= maxResponseCount) {
        setIsLoading(false);
        return;
      } else {
        const lastCharacter = responseList[responseList.length - 1].character;
        // pick a character who didn't just speak
        let character = chooseRandom(lastCharacter);

        const lastResponse = responseList[responseList.length - 1].response;

        if (!!lastResponse && lastResponse.includes('🦇')) {
          addResponse('batman');
        } else {
          addResponse(character);
        }
      }
    } catch (error) {
      const errorMessage =
        // @ts-expect-error: asldfkj
        error?.response?.data?.error ??
        'Something went wrong generating the response';
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (responseList.length !== 0) {
      navigate({});
    }

    loop();
  }, [responseList]);

  const addResponse = (
    character: CharacterType | 'batman',
    response?: string,
  ) => {
    const uid = generateUniqueId();
    setResponseList((prevResponses) => {
      const newList = [
        ...prevResponses,
        {
          id: uid,
          response,
          character,
        },
      ];
      return newList;
    });
    return uid;
  };

  const updateResponse = (
    uid: string,
    updatedObject: Record<string, unknown>,
  ) => {
    setResponseList((prevResponses) => {
      const updatedList = [...prevResponses];
      const index = prevResponses.findIndex((response) => response.id === uid);
      if (index > -1) {
        updatedList[index] = {
          ...updatedList[index],
          ...updatedObject,
        };
      }
      const conversation = to64(updatedList);
      FullStory.event('Conversation', {
        url:
          window.location.href.split('?')[0] + '?conversation=' + conversation,
      });
      return updatedList;
    });
  };

  // choose a random item in the list
  const getRandomItem = (list: string[]) => {
    return list[Math.floor(Math.random() * list.length)];
  };

  const chooseRandom = (
    remove: CharacterType | 'batman' | null,
  ): CharacterType => {
    const characters = ['rick', 'yoda', 'barbie', 'cartman'];

    return getRandomItem(
      characters.filter((i) => i !== remove),
    ) as CharacterType;
  };

  const getGPTResult = async () => {
    // If a response is already being generated or the prompt is empty, return
    if (isLoading || !prompt) {
      return;
    }

    setIsLoading(true);
    setMaxResponseCount((prompt.length % 3) * 2 + 1);
    setPrompt('');
    addResponse('self', prompt);
  };

  const onChangeModel = (e: any) => {
    const { name } = e.target;
    if (name === 'gpt-3.5-turbo') {
      setModel('gpt-3.5-turbo');
    } else {
      setModel('gpt-4');
    }
  };

  const firstPrompt =
    responseList.length > 0
      ? responseList[0].response
      : 'Ask the crew a question!';

  return (
    <div className='App'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>GroupChatGPT</title>
        <meta name='description' content={firstPrompt} />
        <meta name='twitter:title' content='GroupChatGPT' />
        <meta name='twitter:description' content={firstPrompt} />
        <link rel='canonical' href='https://groupchatgpt.rfreling.dev' />
      </Helmet>
      <Toaster
        position='top-right'
        toastOptions={{
          error: {
            duration: 100000,
          },
        }}
      />
      <div id='response-list'>
        <div style={{ textAlign: 'center' }}>
          {/* <RadioButton
            name='gpt-3.5-turbo'
            id='gpt-3.5-turbo'
            value='gpt-3.5-turbo'
            text='GPT-3.5 Turbo'
            onChange={onChangeModel}
            checked={model === 'gpt-3.5-turbo'}
          />
          <RadioButton
            name='gpt-4'
            id='gpt-4'
            value='gpt-4'
            text='GPT-4'
            onChange={onChangeModel}
            checked={model === 'gpt-4'}
          /> */}
          <div
            style={{
              color: 'blue',
              cursor: 'pointer',
              padding: '4px',
              margin: '12px auto 0px',
              width: 'fit-content',
              border: '1px solid blue',
              borderRadius: '4px',
            }}
            onClick={toggleDrawer}>
            What is GroupChatGPT?
          </div>
          <div style={{ margin: '6px auto 0px' }}>
            <span
              style={{
                cursor: 'pointer',
                width: 'fit-content',
                padding: '4px',
                border: '1px solid black',
                borderRadius: '4px',
              }}
              onClick={copyConversationLink}>
              🔗
            </span>
            &nbsp;|&nbsp;
            <span
              style={{
                color: 'green',
                cursor: 'pointer',
                width: 'fit-content',
                padding: '4px',
                border: '1px solid green',
                borderRadius: '4px',
              }}
              onClick={copyConversation}>
              Share conversation
            </span>
          </div>
        </div>
        <PromptResponseList responseList={responseList} key='response-list' />
      </div>

      <div id='input-container'>
        <PromptInput
          prompt={prompt}
          onSubmit={getGPTResult}
          key='prompt-input'
          updatePrompt={(prompt) => setPrompt(prompt)}
        />
        <button
          id='submit-button'
          className={isLoading ? 'loading' : ''}
          onClick={getGPTResult}></button>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction='left'
        className='drawer'
        size={window.innerWidth < 700 ? '60vw' : '350px'}>
        <ReactMarkdown>{README}</ReactMarkdown>
        <br />
        <br />
        <br />
      </Drawer>
    </div>
  );
};

export default App;
